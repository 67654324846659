import React, {useCallback, useEffect, useRef} from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Image from 'next/image';

import Checkers from '../../../../helpers/class/Checkers';
import ViewPort from '../../../ViewPort';
import Link from "next/link";


export default function ThumbsType ( props ) {

  const itemWrapperClassName = props.hasOwnProperty('itemWrapperClassName') && Checkers.isValidString(props.itemWrapperClassName) ? props.itemWrapperClassName : 'd-flex col-12 col-sm-6 col-md-4 col-lg-3'

  const builder = props.builder;
  const content = props.content;
  const articles = props.articles;

  let container_class = 'container';
  let card_class = 'card shadow-none\ ';
  if (props.type == 'blog') {
    if (Checkers.isValidString(builder.builder_news_view_width)) {
      container_class = builder.builder_news_view_width;
    }
    card_class += Checkers.isValidObjString(builder, 'builder_news_radius') ? `border-radius-${builder.builder_news_radius}\ ` : '';
    card_class += !Checkers.isValidObjPositiveInteger(builder, 'builder_news_border') ? 'bg-transparent' : 'border';

  } else if (props.type == 'category') {
    if (Checkers.isValidString(content.category_view_width)) {
      container_class = content.category_view_width;
    }
    card_class += Checkers.isValidObjString(content, 'tiles_radius') ? `border-radius-${content.tiles_radius}\ ` : '';
    card_class += !Checkers.booleanState(content.view_type_with_border) ? 'bg-transparent' : 'border';

  }

  let skeletonArticles = [];
  for (let i = 0; i < 6; i++) {
    skeletonArticles.push(
        <div key={i.toString()} className="d-flex col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="mb-4">
            <Skeleton height={365} className="col-12"/>
            <Skeleton height={18} className="col-12 col-sm-6 col-md-4"/>
            <Skeleton height={25} className="col-12 col-sm-8 col-md-6"/>
          </div>
        </div>
    );
  }

  const textColor = () => {
    if (props.textColor) {
      return "text-light-color"
    }

    return;
  }

  return (
      <div className={`${container_class} pb-5`}>
        <div className="row w-100 m-0 d-flex justify-content-center">
          {props.loadingArticles ?
              skeletonArticles
              : articles != undefined && articles.length > 0 && articles.map((article, index) => {

                const created = moment(new Date((article.created) * 1000)).format('dddd Do MMMM');
                const title = props.articlesContentType == 'blog' ? created : article.title;
                const subtitle = props.articlesContentType == 'article' ? article.subtitle : props.articlesContentType == 'blog' ? article.title : article.description;

                // card_class += article.has_description == '1' ? '\ cursor-pointer' : '';

                if ((props.hasOwnProperty('articlesLimit') && (index < props.articlesLimit || props.articlesLimit === null)) || !props.hasOwnProperty('articlesLimit')) {
                  return (
                      <ViewPort
                          parentClassName={'col-12 col-sm-6 col-md-4 col-lg-3'}
                          key={article.id.toString()}
                          skeletonItem={
                            <div className="mb-4">
                              <Skeleton height={365} className="col-12"/>
                              <Skeleton height={18} className="col-12 col-sm-6 col-md-4"/>
                              <Skeleton height={25} className="col-12 col-sm-8 col-md-6"/>
                            </div>
                          }
                      >
                        <div className={itemWrapperClassName}>
                          <Link href={props.updateHref( article )}>
                            <a onClick={() => props.clickHandler(article)} className={props.setClass(article, card_class) + ' ' + (Checkers.isValidString(props.itemClassName) ? props.itemClassName : '')}
                               style={{
                                 cursor: article.has_description == "1" ? 'pointer' : null,
                                 backgroundColor: props.backgroundColor != null ? props.backgroundColor : ""
                               }}>
                              <div className="bg-master-light" style={{height: 365}}>
                                {
                                    Checkers.isValidString(article.image) &&
                                    <Image
                                        layout={'fill'}
                                        sizes={'75vw'}
                                        quality={'75'}
                                        src={article.image}
                                        alt={'Post Img'}
                                        placeholder={'blur'}
                                        blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                    />
                                }
                              </div>
                              <div className="card-body">
                                <div className="text-uppercase hint-text fw-bold small-text text-master text-design-color">
                                  {title}
                                </div>
                                <p className={textColor() + " fw-bold text-black line-clamp-2"}>
                                  {subtitle}
                                </p>
                              </div>
                            </a>
                          </Link>
                        </div>
                      </ViewPort>
                  )
                }
              })
          }
        </div>
      </div>
  )
}
